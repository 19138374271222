import {Link} from 'gatsby';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import React from "react"
import SEO from "components/seo"
import Envelope from "assets/img/svg/envelope.svg"
import {PageHeader} from "components/PageHeader"
import {PageSubHeader} from "components/PageSubHeader"
import {BanerSmall} from "components/BanerSmall"
import {PackageDescription} from '../components/PageSubHeader/styles';
import {PackageTitle} from '../components/PageSubHeader/styles';
import {StyledParagraph} from '../components/PageSubHeader/styles';
import {List} from '../components/PageSubHeader/styles';
import {ListTitle} from '../components/PageSubHeader/styles';
import {ListWrapper} from '../components/PageSubHeader/styles';
import {Container} from '../components/PageSubHeader/styles';
import {Paragraph} from '../components/Typography';
import {ListItem} from '../sections/News/NewsLayout/List/styles';
import {SectionWrapper} from '../shared/Styles/Wrappers';

const SupportUs = () => {
  return (
    <>
      <SEO
        title={"Pakiety sponsorskie"}
        description={
          "Dobro innych nie jest Ci obojętne i chcesz pomagać tym, którzy najbardziej potrzebują wsparcia? Możesz to zrobić z naszą Fundacją. Pomagamy dzieciom, które znalazły się w trudnej sytuacji, by jak najlepiej przygotować ich na start w dorosłość."
        }
      />
      <PageHeader>Pakiety Sponsorskie - wsparcie dla dzieciaków i Twojej firmy</PageHeader>
      <PageSubHeader>
        Dobro innych nie jest Ci obojętne i chcesz pomagać tym, którzy najbardziej potrzebują wsparcia? Możesz to zrobić z naszą Fundacją. Pomagamy dzieciom, które znalazły się w trudnej sytuacji, by jak najlepiej przygotować ich na start w dorosłość. A ponieważ wierzymy, że wiedza otwiera świat, zapewniamy dostęp do kursów, szkoleń i zajęć oraz stażów i praktyk zawodowych. <strong>Wierzymy, że możecie nam w tym pomóc!</strong> <br/> <br/>

        Jak Twoja firma może zaangażować się w pomoc? Proponujemy specjalne <AnchorLink to='#pakiety-sponsorskie'>Pakiety Sponsorskie.</AnchorLink> Całą wpłaconą kwotę przeznaczymy na sfinansowanie potrzeb naszych podopiecznych.
      </PageSubHeader>

      <PageSubHeader title="Dlaczego warto zostać Sponsorem?">
        Wspierając Fundację, czynisz dobro i masz wpływ na rozwój naszych podopiecznych. Ty ocieplasz wizerunek Twojej firmy, a my dbamy o zwiększenie jej rozpoznawalności, dzięki promocji w dostępnych kanałach marketingowych Fundacji Świat Bez Barier.
        <br/> <br/>
        Na naszych portalach społecznościowych opublikujemy wpisy prezentujące Twoją firmę, a odpowiedniej wielkości logo umieścimy na wszelkiego rodzaju treściach informacyjnych i reklamowych online. Stając się Sponsorem naszej Fundacji, otrzymasz certyfikat Sponsora
        w wersji elektronicznej i papierowej z prawem wykorzystywania go za pośrednictwem wszelkich nośników.
      </PageSubHeader>

      <PageSubHeader title="Jak działają Pakiety Sponsorskie?">
        Każdy Pakiet Sponsorski składa się ze ścieżek rozwoju. To Ty, wybierając jedną z nich, decydujesz o tym, w jakim obszarze będziemy działać dla Ciebie i Twojej firmy. Nasz zespół to doświadczeni specjaliści (posiadają od 5 do nawet 13 lat doświadczenia w danej dziedzinie - łącznie mamy go ponad 80 lat <span role="img" aria-label=''>😉</span>). Chętnie pomogą rozwinąć Twój biznes. <br/> <br/>
<span id="pakiety-sponsorskie"></span>
        Szczegółowy zakres wybranego pakietu i termin wykonania ustalamy wspólnie, by jak najlepiej dopasować go do aktualnych potrzeb przedsiębiorstwa.
      </PageSubHeader>

      <Container>
        <SectionWrapper>
          <PackageTitle ><span role="img" aria-label=''>🟣</span> Pakiet sponsorski I</PackageTitle>
          <PackageDescription>
            <Paragraph>Wysokość wsparcia: <strong>2000zł</strong></Paragraph>
            <Paragraph>Do Wyboru:</Paragraph>
          </PackageDescription>

          <ListWrapper>
            <ListTitle>Rozwój social media</ListTitle>
            <List>
              <ListItem as='li'>
                Przeanalizujemy obecność Twojej marki w mediach społecznościowych (Facebook, Instagram, LinkedIn) i udzielimy instrukcji, jak najlepiej wykorzystać daną platformę.
              </ListItem>
              <ListItem as='li'>
                Skonfigurujemy profesjonalne konta biznesowe na wybranych platformach (Facebook, Instagram, LinkedIn), wskażemy ich najważniejsze funkcje i pokażemy, jak się w nich odnaleźć.
              </ListItem>
            </List>
          </ListWrapper>

          <ListWrapper>
            <ListTitle>ROZWÓJ WIDOCZNOŚCI W GOOGLE</ListTitle>
            <List>
              <ListItem as="li">
                Przeanalizujemy obecność Twojej marki w wyszukiwarce Google i udzielimy instrukcji, co zrobić, by osiągnąć wyższą pozycję.
              </ListItem>
              <ListItem as="li">
                Założymy profesjonalną wizytówkę Google Moja Firma lub wykonamy audyt istniejącej.
              </ListItem>
              <ListItem as="li">
                Utworzymy i skonfigurujemy profesjonalne konto Google Ads.
              </ListItem>
            </List>
          </ListWrapper>

          <ListWrapper>
            <ListTitle>ROZWÓJ IDENTYFIKACJI WIZUALNEJ</ListTitle>
            <List>
              <ListItem as="li">
                Opracujemy do 3 koncepcji projektu logo, z których wybierzesz tą najbardziej pasującą do Twojej firmy.
              </ListItem>
              <ListItem as="li">
                Po naniesieniu ewentualnych zmian (do 2 poprawek) otrzymasz od nas profesjonalne logo zgodne z bieżącymi trendami.
              </ListItem>
            </List>
          </ListWrapper>
        </SectionWrapper>
      </Container>

      <Container>
        <SectionWrapper>
          <PackageTitle><span role="img" aria-label=''>🟣</span> Pakiet sponsorski II</PackageTitle>
          <PackageDescription>
            <Paragraph>Wysokość wsparcia: <strong>4000zł</strong></Paragraph>
            <Paragraph>Do Wyboru:</Paragraph>
          </PackageDescription>

          <ListWrapper>
            <ListTitle>Rozwój social media</ListTitle>
            <List>
              <ListItem as='li'>
                Przeprowadzimy analizę dotychczasowych działań marketingowych Twojej firmy (organicznych oraz płatnych) na platformach Facebook, Instagram, LinkedIn.
              </ListItem>
              <ListItem as='li'>
                Sprawdzimy poprawność konfiguracji narzędzi biznesowych i utworzymy te, których firma nie posiadała do tej pory.
              </ListItem>
              <ListItem as='li'>
                Otrzymasz pakiet informacji i wskazówek, jak promować markę w Social Media, i będziesz mógł skorzystać z godzinnej konsultacji z naszym specjalistą.
              </ListItem>
            </List>

            <StyledParagraph>
              <sup>*</sup> Jeśli do tej pory nie prowadzono działań płatnych, opracujemy plan marketingowy i wykonamy konfiguracje niezbędnych do realizacji planu narzędzi marketingowych w wybranych kanałach Social Media (Facebook, Instagram, LinkedIn).
            </StyledParagraph>
          </ListWrapper>

          <ListWrapper>
            <ListTitle>ROZWÓJ WIDOCZNOŚCI W GOOGLE</ListTitle>
            <List>
              <ListItem as="li">
                Przeanalizujemy dotychczasowe działania reklamowe i organiczne Twojej firmy.
              </ListItem>
              <ListItem as="li">
                Sprawdzimy poprawność konfiguracji i funkcjonowania kluczowych narzędzi: konto Google Ads, Google Analytics, Search Console, Google Tag Manager.
              </ListItem>
              <ListItem as="li">
                Otrzymasz pakiet informacji i wskazówek, jak promować swoją markę w Google, oraz będziesz mógł skorzystać z godzinnej konsultacji z naszym specjalistą.
              </ListItem>
            </List>

            <StyledParagraph>
              <sup>*</sup> Jeśli do tej pory nie prowadzono działań reklamowych, opracujemy plan marketingowy w obszarze wyszukiwarki Google i wykonamy konfiguracje niezbędnych narzędzi.
            </StyledParagraph>
          </ListWrapper>

          <ListWrapper>
            <ListTitle>ROZWÓJ STRONY WWW / ECOMMERCE</ListTitle>
            <List>
              <ListItem as="li">
                Wykonamy analizę kluczowych funkcjonalności strony lub sklepu (również na platformie Allegro). Skupimy się na przygotowaniu ich pod najkorzystniejsze dla Twojej marki działania marketingowe.
              </ListItem>
              <ListItem as="li">
                Przeanalizujemy poprawność i efektywność oferty, wskazując najważniejsze zmiany i udzielając instrukcji, jak je wykonać.
              </ListItem>
              <ListItem as="li">
                Zapewnimy możliwość indywidualnej godzinnej konsultacji z naszym specjalistą.
              </ListItem>
            </List>
          </ListWrapper>
        </SectionWrapper>
      </Container>

      <Container>
        <SectionWrapper>
          <PackageTitle><span role="img" aria-label=''>🟣</span> Pakiet sponsorski III</PackageTitle>

          <PackageDescription>
            <Paragraph>Wysokość wsparcia: <strong>6000zł</strong></Paragraph>
            <Paragraph>Do Wyboru:</Paragraph>
          </PackageDescription>

          <ListWrapper>
            <ListTitle>Rozwój social media</ListTitle>
            <List>
              <ListItem as='li'>
                Wykonamy analizę otoczenia biznesowego.
              </ListItem>
              <ListItem as='li'>
                Opracujemy kampanię marketingową dla Twojej firmy i wyposażymy Cię w niezbędne do jej przeprowadzenia narzędzia.
              </ListItem>
              <ListItem as='li'>
                Wykonamy grafiki reklamowe i udzielimy niezbędnych informacji, jak samodzielnie uruchomić kampanię.
              </ListItem>
              <ListItem as='li'>
                Zapewnimy możliwość indywidualnych dwugodzinnych konsultacji z naszym Marketing Specialist.
              </ListItem>
            </List>
          </ListWrapper>

          <ListWrapper>
            <ListTitle>ROZWÓJ IDENTYFIKACJI WIZUALNEJ</ListTitle>
            <List>
              <ListItem as="li">
                Zaprojektujemy dla Twojej firmy profesjonalne logo, a także księgę znaku, która jednoznacznie określi jego poprawne użytkowanie.
              </ListItem>
              <ListItem as="li">
                W ramach księgi znaku otrzymasz także projekt papieru firmowego (gotowy do wydruku), stopek e-mail, kopert firmowych, wizytówek, gadżetów (kubek, smycz, długopis), ulotek lub voucherów.
              </ListItem>
            </List>
          </ListWrapper>

          <ListWrapper>
            <ListTitle>ROZWÓJ STRONY WWW / ECOMMERCE</ListTitle>
            <List>
              <ListItem as="li">
                Przeanalizujemy potrzeby Twojej firmy i wykonamy odpowiedni Landing Page, który pomoże w promocji przedsiębiorstwa. Określimy jego budowę, zaprojektujemy wygląd i zakodujemy go, dbając o to, by jak najskuteczniej spełnił swoją funkcję.
              </ListItem>
              <ListItem as="li">
                Zapewnimy możliwość indywidualnych dwugodzinnych konsultacji z naszym Projekt Managerem.
              </ListItem>
            </List>
          </ListWrapper>
        </SectionWrapper>
      </Container>

      <PageSubHeader title='Wybierz jeden z Pakietów i pomóż nam spełnić marzenia naszych podopiecznych! 💜'/>

      <BanerSmall
        image={Envelope}
        header="Chcesz nas wesprzeć w inny sposób?"
        subHeader="Skontaktuj się z nami"
        buttonText="Wyślij wiadomość"
        buttonIcon="heart"
        slug="/kontakt"
      ></BanerSmall>
    </>
  )
}

export default SupportUs
